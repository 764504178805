// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";

const POSTHOG_API_KEY = "phc_eoWXa3tHQp5KHQkgUbMiDsuybYp2p9Gpm2tIR3cGOrR";
const POSTHOG_API_HOST = "https://us.i.posthog.com";

export default class PosthogHandler {
  static initialize() {
    posthog.init(POSTHOG_API_KEY, {
      api_host: POSTHOG_API_HOST,
      person_profiles: "identified_only",
    });
  }

  static isFeatureFlagEnabledForVariant(featureFlagKeyName, variantName) {
    return !!(posthog.getFeatureFlag(featureFlagKeyName) === variantName);
  }
}
