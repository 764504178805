import { LoadingOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import PosthogHandler from "lib/posthog";
import ReactDOM from "react-dom";

PosthogHandler.initialize();

const isReferralLinkClick = () => window.location.pathname.match(/^\/r\/.+$/);

const contentToRender = () => {
  if (isReferralLinkClick()) {
    window.location.href = window.location.href.replace(
      "https://referpro.co",
      "https://referrals.referpro.com"
    );
  } else {
    window.location.href = window.location.href.replace(
      "https://referpro.co",
      "https://app.referpro.com"
    );
  }
  return (
    <Result
      style={{ marginTop: 100 }}
      icon={
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24 }}
              spin
            />
          }
          size="large"
        />
      }
      title="One moment . . ."
    />
  );
};

ReactDOM.render(contentToRender(), document.getElementById("root"));
